var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('ShareDialog',{attrs:{"visibleShareDialog":_vm.visibleShareDialog,"practice":_vm.fullPractice},on:{"toggleVisibleShareDialog":_vm.toggleVisibleShareDialog}}),_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('span',{staticStyle:{"opacity":"0"}},[_vm._v("1")])]),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":16}},[_c('h1',[_vm._v(" "+_vm._s(`Result: ${_vm.fullPractice.exam.title}`)+" "),(_vm.isRoleTeacher() || _vm.isRoleAdmin())?_c('router-link',{attrs:{"to":{
            name: 'EditFullPractices',
            params: { fullPracticeId: _vm.$route.params.fullPracticeId }
          }}},[_c('i',{staticClass:"fas fa-edit action-icon"})]):_vm._e()],1)]),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":4}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
            _vm.toggleVisibleShareDialog(true);
          }}},[_c('i',{staticClass:"fas fa-lock-open"}),_vm._v(" "+_vm._s(_vm.$t("practice.form.share"))+" ")])],1)],1),_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.handleTabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c('el-tab-pane',{key:tab.id,attrs:{"label":tab.title,"name":String(tab.id)}},[_c('el-tabs',{model:{value:(_vm.showSectionOrder),callback:function ($$v) {_vm.showSectionOrder=$$v},expression:"showSectionOrder"}},_vm._l((_vm.fullPractice.compose_practice_practices),function(section){return _c('el-tab-pane',{key:section.order,attrs:{"label":`Section ${section.order} - ${section.practice.exam.title}`,"name":String(section.order)}},[(_vm.activeTab === 'all' && (_vm.isRoleAdmin() || _vm.isRoleTeacher()))?_c('div',[_c('el-table',{attrs:{"data":[{ title: 'Percent' }]}},[_c('el-table-column',{attrs:{"fixed":"","label":"#","prop":"title","width":"120px"}}),_vm._l((section.practice.exam.questions),function(question){return _c('el-table-column',{key:question.id,attrs:{"align":"center","label":String(question.order)}},[_c('div',{style:(question.correct_percent !== 100
                      ? 'color: #fff; background: red; text-align: center'
                      : 'color: #3c763d; text-align: center')},[_c('b',[_vm._v(_vm._s(`${question.correct_percent}%`))])])])})],2)],1):_vm._e(),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('Results',{attrs:{"id":_vm.$route.params.fullPracticeId,"isFullPractice":true,"results":_vm.results,"practice":section.practice,"enabledDelete":_vm.isRoleTeacher() || _vm.isRoleAdmin(),"visibleSessionResult":_vm.fullPractice.compose_practice_practices.length > 1},on:{"deletePracticeUserExam":_vm.deletePracticeUserExam}}):_vm._e()],1)}),1),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?[_c('hr',{staticClass:"separate-line"}),_c('h2',[_vm._v("題目詳情")]),_c('el-row',[_c('el-tabs',{model:{value:(_vm.showSectionOrder),callback:function ($$v) {_vm.showSectionOrder=$$v},expression:"showSectionOrder"}},_vm._l((_vm.fullPractice.compose_practice_practices),function(section){return _c('el-tab-pane',{key:section.order,attrs:{"label":`Section ${section.order} - ${section.practice.exam.title}`,"name":String(section.order)}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":[{ title: 'Percent' }]}},[_c('el-table-column',{attrs:{"fixed":"","label":"#","prop":"title"}}),_vm._l((section.practice.exam.questions),function(question){return _c('el-table-column',{key:question.id,attrs:{"align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{class:_vm.currentQuestion &&
                        _vm.currentQuestion.order === question.order
                          ? 'orderTextActiveText'
                          : 'orderText'},[_vm._v(" "+_vm._s(question.order)+" ")])]}},{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":() => {
                          _vm.currentQuestion = question;
                        }}},[_c('div',{style:(question.correct_percent !== 100
                            ? 'color: #fff; background: red; text-align: center'
                            : 'color: #3c763d; text-align: center')},[_c('b',[_vm._v(_vm._s(`${question.correct_percent}%`))])])])]}}],null,true)})})],2)],1)}),1)],1),_c('el-row',[(_vm.currentQuestion)?_c('div',{staticClass:"row"},[(!_vm.isMultiple && _vm.hasPassage)?_c('div',{staticClass:"border col-md-6"},[_c('ViewPassage',{key:_vm.currentQuestion,attrs:{"passage":_vm.currentQuestion.question.passage,"type":_vm.currentQuestion.question_type,"section":_vm.currentQuestion.question.subject.name}})],1):_vm._e(),_c('div',{class:_vm.isMultiple || !_vm.hasPassage ? 'border col-md-12' : 'border col-md-6'},[_c('Question',{attrs:{"testType":_vm.currentQuestion.question_type,"question":_vm.currentQuestion.question,"disabledAction":true,"disableDelete":true,"displayCorrectAnswer":_vm.fullPractice.show === 'CORRECTNESS_AND_EXPLANATION' ||
                    _vm.isRoleTeacher() ||
                    _vm.isRoleAdmin()}})],1)]):_vm._e()])]:_vm._e()],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }