<template>
  <div class="container">
    <ShareDialog
      :visibleShareDialog="visibleShareDialog"
      :practice="fullPractice"
      @toggleVisibleShareDialog="toggleVisibleShareDialog"
    />
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-row>
      <el-col :span="4">
        <span style="opacity: 0;">1</span>
      </el-col>
      <el-col style="text-align: center" :span="16">
        <h1>
          {{ `Result: ${fullPractice.exam.title}` }}
          <router-link
            v-if="isRoleTeacher() || isRoleAdmin()"
            :to="{
              name: 'EditFullPractices',
              params: { fullPracticeId: $route.params.fullPracticeId }
            }"
          >
            <i class="fas fa-edit action-icon" />
          </router-link>
        </h1>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <el-button
          type="primary"
          @click="
            () => {
              toggleVisibleShareDialog(true);
            }
          "
        >
          <i class="fas fa-lock-open" />
          {{ $t("practice.form.share") }}
        </el-button>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab" type="card" @tab-click="handleTabChange">
      <el-tab-pane
        :key="tab.id"
        v-for="tab in tabs"
        :label="tab.title"
        :name="String(tab.id)"
      >
        <el-tabs v-model="showSectionOrder">
          <el-tab-pane
            :key="section.order"
            v-for="section in fullPractice.compose_practice_practices"
            :label="`Section ${section.order} - ${section.practice.exam.title}`"
            :name="String(section.order)"
          >
            <div
              v-if="activeTab === 'all' && (isRoleAdmin() || isRoleTeacher())"
            >
              <el-table :data="[{ title: 'Percent' }]">
                <el-table-column fixed label="#" prop="title" width="120px" />
                <el-table-column
                  align="center"
                  :key="question.id"
                  v-for="question in section.practice.exam.questions"
                  :label="String(question.order)"
                >
                  <div
                    :style="
                      question.correct_percent !== 100
                        ? 'color: #fff; background: red; text-align: center'
                        : 'color: #3c763d; text-align: center'
                    "
                  >
                    <b>{{ `${question.correct_percent}%` }}</b>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <Results
              v-if="isRoleAdmin() || isRoleTeacher()"
              :id="$route.params.fullPracticeId"
              :isFullPractice="true"
              :results="results"
              :practice="section.practice"
              :enabledDelete="isRoleTeacher() || isRoleAdmin()"
              :visibleSessionResult="fullPractice.compose_practice_practices.length > 1"
              @deletePracticeUserExam="deletePracticeUserExam"
            />
          </el-tab-pane>
        </el-tabs>
        <template
          v-if="isRoleAdmin() || isRoleTeacher()"
        >
        <!-- <template
          v-if="
            (fullPractice.show !== 'COMPLETION_ONLY' ||
              isRoleTeacher() ||
              isRoleAdmin()) &&
              !(activeTab === 'all' && (isRoleAdmin() || isRoleTeacher()))
          "
        > -->
          <hr class="separate-line" />
          <h2>題目詳情</h2>
          <el-row>
            <el-tabs v-model="showSectionOrder">
              <el-tab-pane
                :key="section.order"
                v-for="section in fullPractice.compose_practice_practices"
                :label="
                  `Section ${section.order} - ${section.practice.exam.title}`
                "
                :name="String(section.order)"
              >
                <el-table :data="[{ title: 'Percent' }]" style="width: 100%">
                  <el-table-column fixed label="#" prop="title" />
                  <el-table-column
                    align="center"
                    :key="question.id"
                    v-for="question in section.practice.exam.questions"
                  >
                    <template slot-scope="scope" slot="header">
                      <div
                        :class="
                          currentQuestion &&
                          currentQuestion.order === question.order
                            ? 'orderTextActiveText'
                            : 'orderText'
                        "
                      >
                        {{ question.order }}
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <div
                        style="cursor: pointer;"
                        @click="
                          () => {
                            currentQuestion = question;
                          }
                        "
                      >
                        <div
                          :style="
                            question.correct_percent !== 100
                              ? 'color: #fff; background: red; text-align: center'
                              : 'color: #3c763d; text-align: center'
                          "
                        >
                          <b>{{ `${question.correct_percent}%` }}</b>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-row>
          <el-row>
            <div v-if="currentQuestion" class="row">
              <div v-if="!isMultiple && hasPassage" class="border col-md-6">
                <ViewPassage
                  :key="currentQuestion"
                  :passage="currentQuestion.question.passage"
                  :type="currentQuestion.question_type"
                  :section="currentQuestion.question.subject.name"
                />
              </div>
              <div :class="isMultiple || !hasPassage ? 'border col-md-12' : 'border col-md-6'">
                <Question
                  :testType="currentQuestion.question_type"
                  :question="currentQuestion.question"
                  :disabledAction="true"
                  :disableDelete="true"
                  :displayCorrectAnswer="
                    fullPractice.show === 'CORRECTNESS_AND_EXPLANATION' ||
                      isRoleTeacher() ||
                      isRoleAdmin()
                  "
                />
              </div>
            </div>
          </el-row>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ShareDialog from "@/components/practices/ShareDialog";
import Results from "@/components/practices/Results";
import ViewPassage from "@/components/tests/ViewPassage.vue";
import Question from "@/components/tests/Question.vue";
import fullPracticesApi from "@/apis/fullPractices";
import FullPractice from "@/views/practices/FullPractice";
import roleMixin from "@/mixins/role";
import questionAdapter from "@/components/practices/QuestionAdapter";
import { generatorWebSocket } from "@/class/webSocket";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} Results - Ivy-Way Academy`
    };
  },
  mixins: [roleMixin],
  components: { Breadcrumb, ShareDialog, ViewPassage, Question, Results },
  data() {
    return {
      results: [],
      questions: [],
      fullPractice: null,
      currentQuestion: null,
      visibleShareDialog: false,
      activeTab: "all",
      showSectionOrder: "1",
      echo: null,
      currentChannel: ""
    };
  },
  computed: {
    pageTitle() {
      return this.fullPractice ? `${this.fullPractice.exam.title} Result` : "";
    },
    tabs() {
      if (this.isRoleAdmin()) {
        return [
          { id: "all", title: "All" },
          ...this.fullPractice.getTakeSessionClass()
        ];
      } else if (this.isRoleTeacher()) {
        return [{ id: "all", title: "All" }, ...this.getClassOfTeacher()];
      } else {
        return [{ id: "all", title: "All" }];
      }
    },
    isMultiple() {
      return this.currentQuestion.question.type === "multiple";
    },
    hasPassage() {
      return this.currentQuestion.question.passage;
    }
  },
  async created() {
    this.echo = generatorWebSocket(localStorage.id_token);
    await this.fetchFullPracticeResults();
    this.connectFullPracticeResultsWS();
  },
  methods: {
    getClassOfTeacher() {
      return this.fullPractice
        .getTakeSessionClass()
        .filter(
          ({ teacher_user_id }) =>
            this.isRoleAdmin() ||
            teacher_user_id ===
              Number(this.$store.getters["user/getProfile"].id)
        );
    },
    handleTabChange({ name }) {
      this.results = [];
      if (name !== "all") {
        this.fetchFullPracticeResults(name);
        this.connectFullPracticeResultsWS(name);
      } else {
        this.fetchFullPracticeResults();
        this.connectFullPracticeResultsWS();
      }
    },
    getExamQuestionCorrectPercent(questionId) {
      return this.questions.find(questions => questions.id === questionId)
        .correct_percent;
    },
    async fetchFullPracticeResults(classId) {
      const fullpracticeResults = await fullPracticesApi.fetchFullPracticeResults(
        this.$route.params.fullPracticeId,
        classId
      );
      this.updateResultsState(classId, fullpracticeResults);
    },
    async connectFullPracticeResultsWS(classId) {
      this.echo.leave(this.currentChannel);
      if (classId) {
        this.currentChannel = `compose_practice.${this.fullPractice.id}.results.session_class.${classId}`;
      } else {
        this.currentChannel = `compose_practice.${this.fullPractice.id}.results`;
      }

      this.echo
        .private(this.currentChannel)
        .listen(".answer", data => {
          this.updateResultsState(classId, data);
        });
    },
    updateResultsState(classId, { user_exams, questions, compose_practice }) {
      this.questions = questions;
      let results = [];
      if (classId || this.isRoleAdmin()) {
        results = user_exams;
      } else if (this.isRoleStudent()) {
        results = user_exams.filter(
          ({ user_id }) => user_id === this.$store.getters["user/getProfile"].id
        );
      }
      
      this.results = results.map(result => {
        const otherStatus = (result.other_status && result.other_status.length > 0) ? result.other_status[0] : [];
        return {
          ...result,
          other_status: [
            {
              ...otherStatus,
              progress_time: this.getProgressTime(otherStatus)
            }
          ]
        };
      });

      this.fullPractice = new FullPractice({
        ...compose_practice,
        compose_practice_practices: compose_practice.compose_practice_practices.map(
          section => ({
            ...section,
            practice: {
              ...section.practice,
              exam: {
                ...section.practice.exam,
                questions: section.practice.exam.questions.map(question => ({
                  ...questionAdapter(question),
                  correct_percent: this.getExamQuestionCorrectPercent(
                    question.id
                  )
                }))
              }
            }
          })
        )
      });
    },
    getProgressTime(examStatus) {
      let progressTime = 0;
      for (const statusKey in examStatus) {
        if (statusKey.includes("_time")) {
          progressTime += Number(examStatus[statusKey]);
        }
      }
      return progressTime;
    },
    async deletePracticeUserExam(userExamId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await fullPracticesApi.deleteFullPracticeUserExam(userExamId);
        await this.fetchFullPracticeResults(this.activeTab);
        this.$message.success(this.$t("message.delete_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    toggleVisibleShareDialog(type) {
      this.visibleShareDialog = type;
    }
  }
};
</script>

<style>
.orderText {
  width: 100%;
  height: 100%;
  color: #3c763d;
  text-align: center;
}

.orderTextActiveText {
  width: 100%;
  height: 100%;
  background: #3c763d;
  color: #ffffff;
  text-align: center;
}

.border {
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  /* margin: 0 15px; */
  max-height: 600px;
}
</style>
